import * as actionTypes from './actionTypes'
import analytics from '../components/firebase/analytics';

const initialState = {
    standings: null,
    isLoading: true,
    errMess: null,
    tourneyData: null
}

export default function StatsReducer(state = initialState,action){
    switch(action.type){
        
        case actionTypes.USER_SIGNED_OUT:
            return JSON.parse(JSON.stringify(initialState));
            
        case actionTypes.STATS_LOADING:
            return {...state, isLoading: true, errMess: null};

        case actionTypes.STATS_FAILED:
            return {...state, isLoading: false, errMess: action.payload};
        
        case actionTypes.STATS_LOADED:
            analytics.pointsPageAccessed();
            return {...state, isLoading: false, errMess: null, standings: action.payload.standings, tourneyData: action.payload.tourneyData};
        default: 
            return state;
    }
}