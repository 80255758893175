import * as actionTypes from './actionTypes';
import { getMatchStanding } from '../functions/StandingsCalculator';

const initialState = {
    points: [],
    standings: [],
    pointSystem: null,
    currentTeam: {
        teamId: null,
        name:  null
    },
    saveMatchNotification: false
}

export default function NewMatchData(state = initialState,action){
    let newState;
    switch(action.type){
        case actionTypes.USER_SIGNED_OUT:
            return JSON.parse(JSON.stringify(initialState));
        case actionTypes.TEAM_SELECTED_FOR_MATCH:
            const currentTeam = {...state.currentTeam};
            currentTeam.name = action.payload.name;
            currentTeam.teamId = action.payload._id;
            return {...state,currentTeam};
        case actionTypes.TEAM_DESELECTED_FOR_MATCH:
            return {...state, currentTeam:{...(initialState.currentTeam)}};
        
        case actionTypes.ADD_TEAM:
            newState = JSON.parse(JSON.stringify(state));
            newState.points.push(action.payload);
            return newState;

        case actionTypes.REMOVE_TEAM:
            if(state.points.length){
                newState = JSON.parse(JSON.stringify(state)); 
                newState.points.pop();
                newState.standings = getMatchStanding(newState)
                return newState;
            } else {
                return state;
            }
        
        case actionTypes.SET_POINT_SYSTEM:
            newState = JSON.parse(JSON.stringify(state));
            newState.pointSystem = action.payload;
            return newState;
        case actionTypes.CALC_STANDINGS_FOR_MATCH:
            return {...state, standings: getMatchStanding(state)};
        
        case actionTypes.SAVE_MATCH_NOTIFY:
            return {...state, saveMatchNotification: true};
        
        case actionTypes.SAVE_MATCH_CONSUME_NOTIFY:
            return {...initialState};
        case actionTypes.NEW_MATCH_RESET:
            return {...initialState};
        default:
            return state;
    }
}