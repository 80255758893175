import React from 'react';
import {Typography} from '@rmwc/typography';
import { Container, Row, Col } from 'reactstrap';
//import { Ripple } from '@rmwc/ripple';
import { strings } from '../../strings';
import { Button } from '@rmwc/button';
//const bhimUPI = 'abhinavkrin@upi'
/*function copyBhim(){
    const el=document.createElement('input');
    el.setAttribute('type','text');
    el.value = bhimUPI;
    el.style.opacity = 0.1;
    document.body.insertAdjacentElement('beforeend',el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Copied");
}*/
export default function Credit(){
    return (
        <>
                <Container fluid className="p-3">
                <Row>
                        <Col xs={12} sm={6} className="mb-2" style={{display: "flex", flexDirection:"column"}}>
                            <Typography use={"subtitle1"}>Enjoying {strings.appName}?</Typography>
                            <Typography use={"body2"}>
                                The app is free and always will be free to use.
                            </Typography>
                            <Typography use={"subtitle1"}>Get eSportsWeb.in</Typography>
                            <div>
                                <img src="https://esportsweb.in/logo.webp" alt="esportsweb.in" className="rounded-circle"/>
                            </div>
                            <Typography use={"body2"}>
                                <a href="https://esportsweb.in/">eSportsWeb.in</a> is the premium version of tournify. Use custom logo, get top fraggers, custom backgrounds and much more.
                            </Typography>
                            <a href="https://esportsweb.in/" style={{textDecoration: "none"}}>
                                <Button raised>
                                    Exlpore now
                                </Button>
                            </a>
                           {/* <Typography use="body1">My BHIM UPI</Typography>
                            <div>
                            <div>
                                <Typography use="body1"><img src={window.location.origin+'/svgs/bhim.svg'} width="16px" alt="bhim"/>
                                    {bhimUPI}
                                </Typography>
                                {'  '}
                                <Ripple><span onClick={()=>copyBhim()}><span className="fa fa-clone"></span></span></Ripple>
                            </div>
                            </div> */}
                        </Col>
                        <Col xs={12 } sm={6} className="mb-2" style={{display: "flex", flexDirection:"column"}}>
                            <Typography use="subtitle1">Follow us on instagram</Typography>
                            <Typography use="body2"> Follow us on instagram for quick support, requesting features and latest updates.</Typography>
                            <a href="https://www.instagram.com/tournify.in/" style={{textDecoration: "none", width: "300px"}} target="_blank" without="true" rel="noopener noreferrer">
                                <div className="send-instagram">
                                    <span className="fa fa-instagram"></span>
                                    {' '}Follow Tournify on Instagram
                                </div>
                            </a>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}
/*<div>
                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                    <input type="hidden" name="cmd" value="_s-xclick"/>
                                    <input type="hidden" name="hosted_button_id" value="UEKHGR2F3RCQL"/>
                                    <table>
                                    <tbody>
                                    <tr><td><input type="hidden" name="on0" value="Select Donation amount"/>
                                    <Typography use="body1">Select donation amount</Typography>
                                    </td></tr><tr><td><select name="os0">
                                        <option value="Gold">Gold &#8377;20.00 INR</option>
                                        <option value="Diamond">Diamond &#8377;50.00 INR</option>
                                        <option value="Crown">Crown &#8377;100.00 INR</option>
                                        <option value="Ace">Ace &#8377;200.00 INR</option>
                                        <option value="Conquerer">Conquerer &#8377;500.00 INR</option>
                                    </select> </td></tr>
                                    </tbody>
                                    </table>
                                    <input type="hidden" name="currency_code" value="INR"/>
                                    <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!"/>
                                    <img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1"/>
                                </form>
                            </div>*/