export const baseURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:3001" : "https://admin.tournify.in";
//'https://pubgtourneytool-rsxggjujea-uc.a.run.app'
//"http://localhost:3001";
// "https://admin.tournify.in";
export const appUrl = document.location.origin;
export const recaptchaSiteKey  = '6Lcbo7MZAAAAAJaHkgGWDAwUh2FHQ5au0yPwE_0Z';
export const vapid = "BOH62uHMBhzYMIE0VK4RKoM0RR0IGu73QwREoRJIhgndNLUE58fBNrtuq6wS4j1QpzUFQBa5-3rbKjSw3_Jco3c";
export const restEndPoints = {
    tournaments: '/tournament',
    createTournament: '/tournament/create',
    tournamentFull: '/full',
    team: '/team',
    match: '/match',
    stats: "/stats",
    feedback: "/feedback",
    form: '/form',
    approve: '/approve',
    createForm: '/create'
}
let maxTournamentCapacity = 10; // 10 tournaments
export const getMaxTournamentCapacity = () => maxTournamentCapacity;
export const setMaxTournamentCapacity = (capacity) => maxTournamentCapacity = capacity;

let tournamentValidityInSeconds = 2*30*24*3600; // 2 months
export const setTournamentValidityInSeconds = (seconds) => tournamentValidityInSeconds = seconds;
export const getTournamentValidityInSeconds = () => tournamentValidityInSeconds;