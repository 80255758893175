export const createTournamentForm = {
    name:'',
    organiser:'',
    type: 'SQUAD',
    pointSystem: {
        killPoint: '1',
        placePoint: '1=15,\n2=12,\n3=10,\n4=8,\n5=6,\n6=4,\n7=2,\n8-12=1,\n13-16=0'
    }
} 

export const newTeam = {
    name: '',
    playerList: []
}

export const newMatch = {
    map: 'ERANGEL',
    points: [],
    pointSystem: {},
    result: {
        teamName: '',
        kills: '',
        teamId: null
    }
}

export const response = {
    teamName: '',
    playerNames: ['','','','',''],
    phone: '',
    email: '',
    discord: '',
    countryCode: '91'
}