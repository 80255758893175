import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import * as routes from '../routes';
import FirebaseContext from './firebase/FirebaseContext';
import { Container } from 'reactstrap';
import Loader from './microComponents/loader';
import Helmet from 'react-helmet';
import { strings } from '../strings';


import Header from './Header';
import Footer from './Footer';
import Lander from './Lander';
const BlogPost = lazy(()=> import('./Blog/BlogPost'));
const BlogHome = lazy(()=> import('./Blog/BlogHome'));
const Dashboard = lazy(()=> import('./Dashboard'));
const TournamentView = lazy(()=>import('./TournamentView'));
const TournamentPublic = lazy(()=>import('./TournamentPublic'));
const Stats = lazy(()=> import('./Stats'));
const Guide = lazy(()=> import('./Guide'));
const Privacy = lazy(()=> import('./Privacy'));
const FAQ = lazy(()=> import('./FAQ'));
const Contact = lazy(()=> import('./Contact'));
const Disclaimer = lazy(()=>import('./Disclaimer'));
const TermsOfUse = lazy(()=> import('./TermsOfUse'));
const About = lazy(()=>import('./About'));

class Content extends React.Component {
    render(){
        return (
            <FirebaseContext.Consumer>
                {firebase => 
                        <Switch>
                            <Route path={routes.HOME} exact component={()=>
                                <React.Fragment>
                                    <Helmet>
                                        <title>Tournament management app for eSports | Points Table Maker | {strings.appName}</title>
                                        <meta name="description" content={strings.homeDesc} />
                                    </Helmet>
                                    <Header />
                                    <Lander/>
                                    <Footer firebase={firebase}/>
                                </React.Fragment>
                            } />
                            <Route path={routes.BLOG} exact render={()=>
                                <React.Fragment>
                                    <Helmet>
                                        <title>BLOG | Page 1 | {strings.appName}</title>
                                    </Helmet>
                                    <Header/>
                                    <Suspense fallback={<Loader/>}>
                                        <BlogHome page={1}/>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </React.Fragment>
                            } />
                            <Route path={routes.BLOG+"/page/:page"} exact render={({match})=>
                                <React.Fragment>
                                    <Helmet>
                                        <title>BLOG | Page {match.params.page} | {strings.appName}</title>
                                    </Helmet>
                                    <Header/>
                                    <Suspense fallback={<Loader/>}>
                                        <BlogHome page={match.params.page}/>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </React.Fragment>
                            } />
                            <Route path={routes.BLOG+"/:slug"} exact render={({match})=>
                                <React.Fragment>
                                    <Header/>
                                    <Suspense fallback={<Loader/>}>
                                        <BlogPost slug={match.params.slug}/>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </React.Fragment>
                            } />
                            <Route path={routes.DASHBOARD} exact component={ () =>
                                <React.Fragment>
                                    <Helmet>
                                        <title>Dashboard | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Dashboard firebase={firebase}/>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </React.Fragment>
                            } />
                            <Route path={routes.DASHBOARD+'/:tourneyId'} component={({match}) =>
                                <Suspense fallback={<Loader/>}>
                                     <Header />
                                    <TournamentView firebase={firebase} tournamentId ={match.params.tourneyId} />
                                    <Footer firebase={firebase}/>
                                </Suspense>  }/>
                            <Route path={routes.ABOUT} render={()=>
                                <>
                                    <Helmet>
                                    <title>About | {strings.appName}</title>
                                    <meta name="description" content={strings.aboutDesc} />
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <About/>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.CONTACT} render={()=>
                                <>
                                    <Helmet>
                                    <title>Contact | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <Contact/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.DISCLAIMER} render={()=>
                                <>
                                    <Helmet>
                                    <title>Disclaimer | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <Disclaimer/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.TERMSOFUSE} render={()=>
                                <>
                                    <Helmet>
                                    <title>Terms of use | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <TermsOfUse/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.FAQ} render={()=>
                                <>
                                    <Helmet>
                                    <title>FAQ | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <FAQ/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.GUIDE} render={()=>
                                <>
                                    <Helmet>
                                    <title>Quick guide to create and manage eSports Tournaments | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <Guide/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.PRIVACY} render={()=>
                                <>
                                    <Helmet>
                                    <title>Privacy Policy | {strings.appName}</title>
                                    </Helmet>
                                    <Header />
                                    <Suspense fallback={<Loader/>}>
                                        <Container className="p-5">
                                            <Privacy/>
                                        </Container>
                                    </Suspense>
                                    <Footer firebase={firebase}/>
                                </>
                            }/>
                            <Route path={routes.TOURNAMENT+'/:formId'} component={({match}) =>
                                <Suspense fallback={<Loader/>}>
                                    <Header />
                                    <TournamentPublic formId ={match.params.formId} />
                                    <Footer firebase={firebase}/>
                                </Suspense>
                            }/>
                            <Route path="/points/:tourneyId" exact render={({match})=>
                                <Suspense fallback={<Loader/>}>
                                    <Stats tourneyId={match.params.tourneyId}/>
                                </Suspense>
                            } />
                            <Redirect to={routes.HOME} />
                        </Switch>
                }
            </FirebaseContext.Consumer>
            );        
    }
}

export default withRouter(Content);
