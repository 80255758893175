import * as actionTypes from './actionTypes';
import { removeTeamFromMatchPoints } from '../functions/helper';
import analytics from '../components/firebase/analytics';
const initialState = {
    isLoading: true,
    tournaments: [],
    firstFetchComplete: false,
    errMess: null,
    creating: false,
    createError: null,
    createNotify: false,
    newTourneyId: null,
    activeTournament: null,
    activeTournamentLoading: true,
    activeTournamentErr: null,
    loadedCache: {},
    teamCreating: false,
    teamCreateErr: null,
    savingMatch: false,
    saveMatchErr:  null,
    updatingTournament: false,
    updateTournamentErr:  null,
    updateTournamentNotification: false,
    creatingForm: false,
    createFormErr: null,
    updatingFormSetting: false,
    updateFormSettingErr: null,
    isFormLoading: false,
    formDataErr: null,
    approvingResponse: {},
    approvingResponseErr: {},
    togglingTournamentStatus: false,
    togglingTournamentStatusErr: {},
}
function saveCatch(state) {
    state.loadedCache[state.activeTournament._id] = state.activeTournament   
}
export const Tournaments = (state = initialState,action) =>{
    let newState3,i;
    switch(action.type){
        case actionTypes.USER_SIGNED_OUT:
            return JSON.parse(JSON.stringify(initialState));
        case actionTypes.TOURNAMENTS_LOADING:
            return {...state, isLoading: true};
        

        case actionTypes.TOURNAMENTS_FETCH_FAILED:
            return {...state,isLoading:false, errMess: action.payload};

        case actionTypes.TOURNAMENTS_FETCHED:
            return {...state,isLoading: false, errMess: null, tournaments: action.payload, firstFetchComplete: true};

        case actionTypes.TOURNAMENT_CREATION_IN_PROGRESS:
            return {...state, creating: true, createError: null};

        case actionTypes.TOURNAMENT_CREATION_FAILED:
            return {...state, creating: false, createError: action.payload};

        case actionTypes.TOURNAMENT_CREATED: 
            analytics.tournamentCreated();
            let newState = {...state};
            newState.tournaments.push(action.payload);
            newState.creating = false;
            newState.createError = null;
            return newState;

        case actionTypes.TOURNAMENT_CREATED_NOTIFY:
            return {...state,createNotify: true, newTourneyId: action.payload};

        case actionTypes.TOURNAMENT_CREATED_CONSUME_NOTIFY:
            return {...state,createNotify: false, newTourneyId: null};

        case actionTypes.TOURNAMENT_DELETED:
            const newState2 = JSON.parse(JSON.stringify(state));
            i = newState2.tournaments.findIndex(item => item._id === action.payload);
            if( i !== -1){
                newState2.tournaments.splice(i,1);
            }
            return newState2;

        case actionTypes.ACTIVE_TOURNAMENT_LOADING: 
            return {...state,activeTournamentLoading: true};

        case actionTypes.ACTIVE_TOURNAMENT_LOADING_FAILED: 

            return {...state, activeTournamentLoading: false, activeTournamentErr: action.payload};

        case actionTypes.ACTIVE_TOURNAMENT_LOADED:
            newState3= {...state};
            newState3.loadedCache[action.payload._id] = action.payload;
            newState3.activeTournamentLoading = false;
            newState3.activeTournamentErr = null;
            newState3.activeTournament = action.payload;
            saveCatch(newState3);
            return newState3;

        case actionTypes.ACTIVE_TOURNAMENT_LOAD_FROM_CACHE:
            newState3= JSON.parse(JSON.stringify(state))
                return {...newState3, 
                    activeTournamentLoading: false, 
                    activeTournamentErr: null, 
                    activeTournament: action.payload};

        case actionTypes.TEAM_CTREATING:
            return {...state,teamCreating: true, teamCreateErr: null};
        case actionTypes.TEAM_CREATION_FAILED:
            return {...state, teamCreating: false, teamCreateErr: action.payload};
        case actionTypes.TEAM_CREATED:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.teamCreating = false;
            newState3.teamCreateErr = null;
            newState3.activeTournament.teams.push(action.payload);
            saveCatch(newState3);
            analytics.teamCreated();
            return newState3;

        case actionTypes.DELETED_TEAM:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.activeTournament.teams=newState3.activeTournament.teams.filter(team => team._id!==action.payload.teamId );
            const matchMap = new Map();
            newState3.activeTournament.matches.forEach( match => {
                matchMap.set(match._id,match);
            });
            action.payload.linkedMatches.forEach(matchId => {
                const match = matchMap.get(matchId);
                match.points = removeTeamFromMatchPoints(match,action.payload.teamId);
            });
            saveCatch(newState3);
            return newState3;
        
        case actionTypes.SAVING_MATCH:
            return {...state, savingMatch: true, saveMatchErr: null};
        
        case actionTypes.SAVE_MATCH_ERR:
            return {...state, savingMatch: false, saveMatchErr: action.payload};
        
        case actionTypes.MATCH_SAVED:
            newState3 =  JSON.parse(JSON.stringify(state));
            newState3.activeTournament.matches.push(action.payload);
            newState3.saveMatchErr = null;
            newState3.savingMatch = false;
            saveCatch(newState3);
            analytics.matchCreated();
            return newState3;
        case actionTypes.MATCH_DELETED:
            newState3 =  JSON.parse(JSON.stringify(state));
            let j = newState3.activeTournament.matches.findIndex(match=> match._id === action.payload);
            if(j !== -1)
                newState3.activeTournament.matches.splice(j,1);
            saveCatch(newState3);
            return newState3;

        case actionTypes.UPDATING_TOURNAMENT:
            return {...state, updatingTournament: true, updateTournamentErr: false};
        
        case actionTypes.UPDATE_TOURNAMENT_FAILED:
            return {...state, updatingTournament: false, updateTournamentErr: action.payload};
        
        case actionTypes.UPDATED_TOURNAMENT:
            newState3 =  JSON.parse(JSON.stringify(state));
            newState3.updateTournamentErr = null;
            newState3.updatingTournament = false;
            newState3.updateTournamentNotification = true;
            newState3.activeTournament = {...newState3.activeTournament, ...action.payload}
            i = newState3.tournaments.findIndex(tour=> tour._id === action.payload._id);
            if(i !== -1)
                newState3.tournaments[i] = {...newState3.tournaments[i], ...action.payload}
            saveCatch(newState3);
            return newState3
        case actionTypes.UPDATED_TOURNAMENT_NOTIFICATION_CONSUME:
            return {...state, updateTournamentNotification: false};        

        case actionTypes.CREATING_FORM: 
            return {...state, creatingForm: true, createFormErr: null};
        
        case actionTypes.CREATE_FORM_FAILED:
            return {...state, creatingForm: false, createFormErr: action.payload};

        case actionTypes.FORM_CREATED:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.creatingForm = false;
            newState3.createFormErr = null;
            newState3.activeTournament.form = action.payload;
            newState3.activeTournament.form.approved = action.payload.responses.filter(response => response.isApproved);
            newState3.activeTournament.form.pending = action.payload.responses.filter(response => !response.isApproved);
            saveCatch(newState3);
            return newState3;
        
        case actionTypes.UPDATING_FORM_SETTINGS:
            return {...state, updatingFormSetting: true, updateFormSettingErr: null};
        
        case actionTypes.UPDATE_FORM_SETTINGS_FAILED:
            return {...state, updatingFormSetting: false, updateFormSettingErr: action.payload}

        case actionTypes.UPDATED_FORM_SETTINGS:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.updatingFormSetting = false;
            newState3.updateFormSettingErr = null;
            newState3.activeTournament.form = {...newState3.activeTournament.form, ...action.payload};
            saveCatch(newState3);
            return newState3;
        case actionTypes.TOGGLING_TOURNEY_STATUS:
            return {...state, togglingTournamentStatus: true, togglingTournamentStatusErr: {}};
        
        case actionTypes.TOGGLING_TOURNEY_STATUS_ERR:
            return {...state,togglingTournamentStatus: false, togglingTournamentStatusErr: action.payload};
        
        case actionTypes.TOGGLED_TOURNEY_STATUS:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.activeTournament.form.isOpen = action.payload.isOpen;
            newState3.togglingTournamentStatus = false;
            newState3.togglingTournamentStatusErr = {};
            saveCatch(newState3);
            return newState3;

        case actionTypes.LOADING_FORM_DATA:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.isFormLoading = true;
            newState3.formDataErr = null;
            saveCatch(newState3);
            return newState3;
        case actionTypes.LOAD_FORM_DATA_ERR:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.isFormLoading = false;
            newState3.formDataErr = action.payload;
            saveCatch(newState3);
            return newState3;
        case actionTypes.LOADED_FORM_DATA:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.isFormLoading = false;
            newState3.formDataErr = null;
            action.payload.responses.reverse();
            newState3.activeTournament.form = action.payload;
            newState3.activeTournament.form.approved = action.payload.responses.filter(response => response.isApproved);
            newState3.activeTournament.form.pending = action.payload.responses.filter(response => !response.isApproved);
            saveCatch(newState3);
            return newState3;
        
        case actionTypes.APPROVING_RESPONSE:
            newState3 = JSON.parse(JSON.stringify(state));
            newState3.approvingResponse[action.payload] = true;
            delete newState3.approvingResponseErr[action.payload];
            return newState3;
            
        case actionTypes.APPROVING_RESPONSE_ERR:
            newState3 = JSON.parse(JSON.stringify(state));
            delete newState3.approvingResponse[action.payload.id];
            newState3.approvingResponseErr[action.payload.id] = action.payload.err;
            return newState3;
        
        case actionTypes.APPROVED_RESPONSE:
            newState3 = JSON.parse(JSON.stringify(state));
            const response = newState3.activeTournament.form.responses.find(response=> response._id === action.payload );
            delete newState3.approvingResponse[action.payload];
            delete newState3.approvingResponseErr[action.payload];
            response.isApproved = true;
            newState3.activeTournament.form.approved = newState3.activeTournament.form.responses.filter(response => response.isApproved);
            newState3.activeTournament.form.pending = newState3.activeTournament.form.responses.filter(response => !response.isApproved);
            saveCatch(newState3);
            analytics.teamApproved();
            return newState3;
        default:
            return state;
    }
}