import * as events from './events';
class Analytics{
    analytics = null;
    tournamentCreated(){
        if(this.analytics){
            this.analytics().logEvent(events.TOURNAMENT_CREATED);
        }
    }
    matchCreated(){
        if(this.analytics){
            this.analytics().logEvent(events.MATCH_CREATED);
        }
    }
    teamCreated(){
        if(this.analytics){
            this.analytics().logEvent(events.TEAM_CREATED);
        }
    }
    pointsPageAccessed(){
        if(this.analytics){
            this.analytics().logEvent(events.POINTS_PAGE_ACCESS);
        }
    }
    pointsTableDownload(){
        if(this.analytics){
            this.analytics().logEvent(events.PT_DOWNLOADED);
        }
    }
    responseAdded(){
        if(this.analytics){
            this.analytics().logEvent(events.RESPONSES_ADDED);
        }
    }
    teamApproved(){
        if(this.analytics){
            this.analytics().logEvent(events.TEAM_APPROVED);
        }
    }
    appInstall(){
        if(this.analytics){
            this.analytics().logEvent(events.APP_INSTALL);
        }
    }
    pushSubscribed(){
        if(this.analytics){
            this.analytics().logEvent(events.PUSH_SUBSCRIBED);
        }
    }
    initialise(analytics){
        this.analytics = analytics;
    }
    isInitialised(){
        return this.analytics !== null;
    }

}
const analytics = new Analytics();
export default (analytics);