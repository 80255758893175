import * as actionTypes from '../actionTypes'

export const userSingnedIn = (user) => dispatch=> {
    dispatch({
        type: actionTypes.USER_SIGNED_IN,
        payload: user
    });
    dispatch(hideSignIn())
}


export const userSignedOut = () => ({
    type: actionTypes.USER_SIGNED_OUT
})

export const showSignIn= () => ({
    type: actionTypes.SHOW_SIGN_IN
})

export const hideSignIn = () => ({
    type: actionTypes.HIDE_SIGN_IN
})