import React from 'react';
import {
    Navbar,
    Nav,
    NavItem,
    Button,
  } from 'reactstrap';
import {NavLink, withRouter, Link} from 'react-router-dom';
import FirebaseContext from './firebase/FirebaseContext';
import * as routes from '../routes';
import { userSignedOut, userSingnedIn } from '../redux/ActionCreators/userActions';
import { connect } from 'react-redux';
import { strings } from '../strings';
import NotificationAsk from './NotificationAsk';

const UserInfo = (props) => {
    const {name,photoURL,handleSignOut}= props;
    return (
        <>
        <div className="row text-center mb-2">
            <div className="col-12">
                <img src={photoURL} className="avatar img-fluid" alt={name}></img>
            </div>
            <div className="col-12">
                <span style={{padding:"5px"}}>{`${name}!`}</span>
            </div>
            <div className="col"><Button color="link" onClick={handleSignOut}>Sign out <i className="fa fa-sign-out ml-2"></i></Button></div>
        </div>
        <hr/>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}
const mapDispatchToProps = dispatch => ({
    userSignedIn: (user) => dispatch(userSingnedIn(user)),
    userSignedOut: ()=> dispatch(userSignedOut())
})

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            showSignIn: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.toggleSignIn = this.toggleSignIn.bind(this);
    }
    toggleSignIn(){
        this.setState(prev=>({
            ...prev,
            showSignIn: !prev.showSignIn
        }))
    }
    toggle(){
        this.setState(prev=>({
            ...prev,
            isOpen: !prev.isOpen
        }))
    }
    async handleSignOut(firebase,userSignedOut){
        await firebase.auth().signOut().then(()=>userSignedOut());
        this.props.history.push('/'); 
    }
    handleToggle(e){
        if(!(e.target.closest('.navbar-container'))&&this.state.isOpen){
            this.toggle();
        }
    }
    componentDidMount(){
        document.body.addEventListener("click",this.handleToggle);
    }
    componentWillUnmount(){
        document.body.removeEventListener("click",this.handleToggle)
    }
    render(){
        return (
            <FirebaseContext.Consumer>
            {
                firebase=>
                    <>
                <div className="sticky-top navcustom">
                        <Navbar light className="navcustom">
                            <Link className="logo-text" to="/">
                                <div className="navbar-brand">
                                    <img className="logo" src={document.location.origin+'/svgs/logo.svg'} alt={strings.appName}></img>
                                    <span className="logo-text">{'  '+strings.appNameShort}</span></div>
                            </Link>
                            <div className="navbar-toggler" onClick={()=>this.toggle()} >
                                <button className="nav-btn fa fa-bars fa-lg"></button>
                            </div>                    
                    </Navbar>
                </div>
                {
                    this.props.user &&
                        <NotificationAsk firebase={firebase}/>
                }
                <div className="navbar-container" style={{
                    width: this.state.isOpen?"300px":"0px", 
                    boxShadow: this.state.isOpen? "-0.225rem 0 0.7rem 0.3rem rgba(0, 0, 0, 0.075)":"none",
                    height: "100%",
                                }}>
                                <div className="navbar-content">
                                    {
                                        this.props.user?
                                        <UserInfo name={this.props.user.displayName} photoURL={this.props.user.photoURL} handleSignOut={() =>this.handleSignOut(firebase,this.props.userSignedOut)}/>
                                        :
                                        ''
                                    }
                                    <Nav navbar>
                                        <NavItem>
                                            <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.HOME}>
                                                <i className="fa fa-home"></i>&nbsp;Home
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.ABOUT}><i className="fa fa-info-circle"></i>&nbsp;About Us</NavLink>
                                        </NavItem>
                                        <NavItem>
                                                <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.DASHBOARD}><i className="fa fa-tachometer"></i>&nbsp;Dashboard</NavLink>
                                        </NavItem>
                                        <NavItem>
                                                <a className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} href={routes.BLOG}><i className="fa fa-pencil-square"></i>&nbsp;News & Blogs</a>
                                        </NavItem>
                                        <NavItem>
                                                <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.GUIDE}><i className="fa fa-book"></i>&nbsp;How to?</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.CONTACT}><i className="fa fa-envelope"></i>&nbsp;Contact Us</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink exact className="nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} to={routes.FAQ}><i className="fa fa-question-circle"></i>&nbsp;FAQ</NavLink>
                                        </NavItem>                                        
                                        <NavItem>
                                                <a className="nav-link nav-link2 nav-link pr-4 pl-4" onClickCapture={this.toggle} href="https://esportsweb.in"><i className="fa fa-pencil-square"></i>&nbsp;eSportsWeb.in</a>
                                        </NavItem>
                                        {
                                        !this.props.user &&
                                        <NavItem>
                                                <NavLink exact to={routes.DASHBOARD} onClickCapture={this.toggle} activeClassName="" className="nav-link2 nav-link pr-4 pl-4" onClick={this.toggleSignIn}>
                                                    <i className="fa fa-sign-in"></i>&nbsp;Sign In
                                                </NavLink>
                                        </NavItem>
                                    }
                                    <div className="nav-toggle">
                                        <span onClick={this.toggle}>close{' '}<i className="fa fa-long-arrow-right"></i></span>
                                    </div>
                                </Nav>
                            </div>  
                        </div>  
                    </>
            }
            </FirebaseContext.Consumer>
        );
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));
