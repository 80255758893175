import React, { useEffect, useRef, useState } from 'react';
import MainComponent from './components/MainComponent';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '@rmwc/theme/styles';
import '@rmwc/textfield/styles';
import '@rmwc/button/styles';
import '@rmwc/select/styles';
import '@rmwc/ripple/styles';
import '@rmwc/switch/styles';
import '@rmwc/typography/styles';
import '@rmwc/elevation/styles';
import '@rmwc/linear-progress/styles';
import '@rmwc/card/styles';
import './App.css';
import './stats.css';
import FirebaseContext from './components/firebase/FirebaseContext';
import Firebase from './components/firebase/firebase';
import {Provider} from 'react-redux';
import {configureStore} from './redux/configureStore';
import { BrowserRouter } from 'react-router-dom';
import analytics from './components/firebase/analytics';
import Notification from './components/microComponents/notification';
import ScrollToTop from './components/microComponents/ScrollToTop';
import { setMaxTournamentCapacity, setTournamentValidityInSeconds } from './config';

const store = configureStore();

function App() {
  const [init,setInit] = useState(false);
  const [,setConfigFetched] = useState(false);
  const firebaseref = useRef();
  useEffect(()=>{
    if(firebaseref.current) 
      return; 
    const firebase = new Firebase();
    firebaseref.current = firebase;
    analytics.initialise(firebase.analytics)
    firebase.remoteConfig.fetchAndActivate()
    .then(()=> {
      // const inMaintainence = firebase.remoteConfig.getValue('inMaintainence').asBoolean();
      const tournamentLimitsConfig =  JSON.parse(firebase.remoteConfig.getValue('tournaments').asString());
      setMaxTournamentCapacity(tournamentLimitsConfig.maxTournamentCapacity);
      setTournamentValidityInSeconds(tournamentLimitsConfig.tournamentValidityInSeconds);
      setConfigFetched(true);
    })
    .catch(console.error);
    setInit(true);
  },[]);
  if(init){
    return (
    <FirebaseContext.Provider value={firebaseref.current} >
      <Provider store = {store}>
        <BrowserRouter>
          <ScrollToTop />
          <MainComponent/>
          <Notification/>
        </BrowserRouter>
      </Provider>
    </FirebaseContext.Provider>
    );
  } else {
    return <div/>
  }
}

export default App;
