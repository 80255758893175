import {validators as v} from './validator';
export const decode = (val) => decodeURI(escape(atob(val)));
export const encode = val => btoa(unescape(encodeURI(val)));

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
  };
export const parseDate = time => {
    return new Intl.DateTimeFormat('en-GB',{year: 'numeric', month: 'short', day:'2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).format(new Date(Date.parse(time)))
}

export const parseDateAndTime = time => {
    return new Intl.DateTimeFormat('en-GB',options).format(new Date(Date.parse(time)))
}

export const removeTeamFromMatchPoints = (match,teamId) => {
    const points = match.points.filter( team => team.teamId !== teamId);
    return points;
} 

export function getPointSystemError(val){
    let pointSystem = val.replace(/\s/g,'').split(',');
    let points = [], i = 0, dp = {},a,b,max=-1,flag= [];
    try {
        pointSystem.forEach( (el) => {
              if(!el)
              return;
            let p = el.split('=');
            if( ! (v.isNumber(p[1]) && v.isRange(p[0]) ) ){
                const err =  new Error("Invalid Format. Use only numbers (1-100), '-' , '=' and ','\ne.g.\n1 = 20,\n2 = 14,\n3-5 = 10");
                throw err;
            }
            if(p[0].indexOf('-') !== -1){
                [a,b] = p[0].split('-');
                a = parseInt(a)-1;
                b = parseInt(b)-1;
            } else {
                a = b = parseInt(p[0]) - 1;
            }
            if(a < 0 || b < 0 || a > 99 || b > 99){
                const err =  new Error("Ranks must be in the range 1 - 100. In '"+el+"'. ");
                throw err; 
            }

            if(a > b){
                const err =  new Error("Give range in ascending order. In '"+el+"'. ");
                throw err; 
            }
            for(;a<=b;a++){
               if(dp[a]){
                const err =  new Error("Rank "+(a+1)+" is repeated. ");
                throw err;
               } else {
                   dp[a] = true;
                   points[a] = p[1]
               }
            }
              max = max < b ? b : max;
        });
          for( i = 0 ; i <= max ; i++ ){
          if(!dp[i]){
            flag.push(i+1);
          }
        }
          if(flag.length){
          const err =  new Error("Ranks missing: "+flag.join(",")+". ");
          throw err;
        }
    }catch(err) {
        return err.message;
    }
    return 'Error';
}

export function getPointSystem(data){
    let pointSystem = data.split(',')
    let points = [],a,b;
    pointSystem.forEach( (el, index) => {
        if(!el)
            return;
        el=el.replace(/\s/g,'');
        let p = el.split('=');
        if(p[0].indexOf('-') !== -1){
            [a,b] = p[0].split('-');
            a = parseInt(a)-1;
            b = parseInt(b)-1;
        } else {
            a = b = parseInt(p[0]) - 1;
        }
        for(;a<=b;a++){
            points[a] = parseInt(p[1]);
        }
    });
    return points;
}

export function stringifyPointSystem(PS){
    if(PS.length === 0)
    return null;
    let ranges=[],prevRank = 1, prevPoint = PS[0],currRange = prevRank+" = "+prevPoint;
    for(let i=1;i<PS.length;i++){
        if(prevPoint === PS[i]){
            currRange = prevRank + " - " + (i+1) + " = " + prevPoint;
        }
        else {
            ranges.push(currRange);
            prevRank = i+1;
            prevPoint = PS[i];
            currRange = prevRank+" = "+prevPoint;
        }
    }
    if(ranges[ranges.length - 1] !== currRange){
        ranges.push(currRange);
    }
    return ranges.join(',\n');
}