import { Typography } from '@rmwc/typography';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { vapid } from '../config';
import analytics from './firebase/analytics';

function NotificationAsk({firebase}){

    let show = true;
     
    if('Notification' in window){
        if(Notification.permission === "granted" || Notification.permission === "denied"){
            show=false;
        }
    } else {
        show = false;
    }

    const [isOpen,setIsOpen] = useState(show);
    const [isSubscribing,setSubscribing] = useState(false);

    if(!firebase.messaging){
        console.log("FCM not supported. FCM request for permission skipped.");
        return <div/>;
    }

    const onEnableClick = () => {
        if(!firebase)
            return;
        if('Notification' in window){
            setSubscribing(true);
            firebase.messaging.getToken({vapidKey: vapid})
            .then((currentToken) => {
                setSubscribing(false);
                if (currentToken) {
                    analytics.pushSubscribed();
                    setIsOpen(false);
                } else if(Notification.permission === "default"){
                    // Show permission request.
                    setIsOpen(true);
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // showToken('Error retrieving registration token. ', err);
                // setTokenSentToServer(false);
                setSubscribing(false);
                setIsOpen(false);
            });
        }
    }

    if(!isOpen)
        return <div/>
    else 
    return (
        <div className="w-100 text-white pl-2 pr-2 pt-1 pb-1" style={{backgroundColor: "#00796B"}}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Typography use="body2">
                            Please enable notification for important updates & announcements. Don't worry, <b>we won't spam</b>.
                        </Typography>
                        <Button size="sm" onClick={onEnableClick} color="primary">
                            {isSubscribing?"please wait...":"Enable"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NotificationAsk;