export class CustomStorage {
    constructor(store){
        this.name = store;
        this.store =  localStorage.getItem(this.name) ? JSON.parse( localStorage.getItem(this.name)):null;
        if(!this.store){
            this.store = {}
        }
    }
    addItem(key,value){
        this.store[key] = value;
        localStorage.setItem(this.name,JSON.stringify(this.store));
    }
    getItem(key){
        return this.store[key];
    }
    deleteItem(key){
        delete this.store[key];
        localStorage.setItem(this.name,JSON.stringify(this.store));
    }
}