import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/remote-config';
import {getMaxTournamentCapacity, getTournamentValidityInSeconds} from '../../config';
const firebaseConfig = {
    apiKey: "AIzaSyB-WV4hzxVSpHxsgtjTRf76vaILigWb3bE",
    authDomain: "tournify.in",
    projectId: "pubgtourneytool",
    messagingSenderId: "756598053178",
    appId: "1:756598053178:web:6f8e583614e506cee8d803",
    measurementId: "G-SY31XGZP9L"
  };

export default class Firebase {
  constructor(){
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    this.auth = firebase.auth;
    this.analytics = firebase.analytics;
    this.remoteConfig = firebase.remoteConfig();
    this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    this.remoteConfig.defaultConfig = {
      tournament: {
        maxTournamentCapacity: getMaxTournamentCapacity(),
        tournamentValidityInSeconds: getTournamentValidityInSeconds()
      },
      inMaintainence: false
    };
    if(firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    } else {
      console.log("FCM not supported.")
    }
  }
}
