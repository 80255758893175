import * as actionTypes from './actionTypes';

export const User= (state = null, action) =>{
    switch(action.type){
        case actionTypes.USER_SIGNED_IN:
            return {
                displayName: action.payload.displayName,
                photoURL: action.payload.photoURL,
                email: action.payload.email,
                emailVerified: action.payload.emailVerified,
                phoneNumber: action.payload.phoneNumber,
                showSignIn: false
            };
        case actionTypes.USER_SIGNED_OUT:
            return null;
        default:
            return state;
    }
}