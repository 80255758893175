import React from 'react';
export default function Loader({fixed=true}){
    return (
        <div 
        className="d-flex justify-content-center align-self-center" 
         style={fixed?{
            paddingTop: (window.screen.height*0.3).toFixed(0)+"px",
            paddingBottom: (window.screen.width*0.3).toFixed(0)+"px"
        }:{}}>
            <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw --mdc-theme-primary"></i>
            <span className="sr-only">Loading...</span>
        </div>
    )
}