import React from 'react';

class JumbotronButton extends React.Component {
    render(){
        return (
            <button className="action-button"><span>Go to Dashboard</span></button>
        );
    }
}

export default JumbotronButton;