import { Button } from '@rmwc/button';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { appUrl } from '../config';
import { strings } from '../strings';
class DevInfo extends React.Component {
    shouldComponentUpdate(){
        return false;
    }
    render(){
        return (
            <div className="dev-info" >
                <Container>
                    <Row className="mb-0 p-5">
                        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                            <img className="dev-image rounded-circle" style={{backgroundColor: "steelblue"}} src={`${appUrl}/images/abhinav.jpg`} alt="Abhinav Kumar"></img>
                        </Col>
                        <Col xs={12} md={7} className="d-flex align-items-center justify-content-center text-center lead dev-text">
                        <div>
                        <Row>
                            <p>Hi, I am Abhinav, the creator and maintainer of {strings.appName}. I am a full stack web developer, blogger and a competitve programmer. Send me message on any of my social media handle. I would love to connect with you all.</p>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                {/* <a className="btn btn-social-icon btn-facebook" href="http://www.facebook.com/avitechlab"><i className="fa fa-facebook fa-lg"></i></a>
                                <a className="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/in/abhinavkrin/"><i className="fa fa-linkedin fa-lg"></i></a>
                                <a className="btn btn-social-icon btn-twitter" href="http://twitter.com/abhinavkr_in"><i className="fa fa-twitter fa-lg"></i></a>
                                <a className="btn btn-social-icon btn-github" href="https://github.com/abhinavkrin"><i className="fa fa-github fa-lg"></i></a>
                                <a className="btn btn-social-icon btn-email" href="mailto:contact@avitechlab.com"><i className="fa fa-envelope fa-lg"></i></a> */}
                                <a href="https://avitechlab.com" style={{textDecoration: "none"}}><Button raised>SAY HI! 👋</Button></a>
                            </Row>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default DevInfo;