import React from 'react';
import { userSingnedIn, userSignedOut, showSignIn } from '../redux/ActionCreators/userActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PRIVACY, HOME, CONTACT, FAQ, DASHBOARD, TERMSOFUSE, DISCLAIMER, ABOUT, BLOG } from '../routes';
import Credit from './Stats/Credit';
const mapStateToProps = state => {
    return {
        user: state.user
    }
}
const mapDispatchToProps = dispatch => ({
    userSignedIn: (user) => dispatch(userSingnedIn(user)),
    userSignedOut: ()=> dispatch(userSignedOut()),
    showSignIn: ()=> dispatch(showSignIn())
})
class Footer extends React.PureComponent {
    componentDidMount(){
        const firebase = this.props.firebase;
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
              const user1 = {...user};
              if(!user1.photoURL)
                user1.photoURL = document.location.origin + '/images/nodp.jpg';
              if(!user1.displayName)
                user1.displayName = user1.email;
              this.props.userSignedIn(user1);                
          }
          else {
            this.props.showSignIn();
        }
        });
    }
    render(){ 
        return (
            <>
            <div className="footer">
                <div className="container mr-auto ml-auto">
                    <Credit/>
                    <hr/>
                    <div className="row d-flex justify-content-around m-0 pt-3">                        
                        <div className="col-auto">
                            <Link to={HOME} className="footer-link">Home</Link>
                        </div>
                        <div className="col-auto">
                            <Link to={ABOUT} className="footer-link">About</Link>
                        </div>  
                        <div className="col-auto">
                            <a href={BLOG} className="footer-link">Blog</a>
                        </div>  
                        <div className="col-auto">
                            <Link to={DASHBOARD} className="footer-link">Dashboard</Link>
                        </div>                        
                        <div className="col-auto">
                            <Link to={PRIVACY} className="footer-link">Privacy</Link>
                        </div>
                        <div className="col-auto">
                            <Link to={TERMSOFUSE} className="footer-link">Terms of use</Link>
                        </div>
                        <div className="col-auto">
                            <Link to={DISCLAIMER} className="footer-link">Disclaimer</Link>
                        </div>
                        <div className="col-auto">
                            <Link to={CONTACT} className="footer-link">Contact</Link>
                        </div>
                        <div className="col-auto">
                            <Link to={FAQ} className="footer-link">FAQ</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer2 text-center">
                
                <span>© Copyright {new Date().getFullYear()} Abhinav Kumar</span>
            </div>
            </>
        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Footer);
/*<a style={{textDecoration: "none"}}
                href="https://avitechlab.com" target="_blank" without="true" rel="noreferrer noopener">
                    <span className="avitechlab">by avitechlab.com</span> 
                </a>*/