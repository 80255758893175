const compareMatch = (a,b)=> {
    if(a.matchPoint===b.matchPoint){
       if(a.placePoint === b.placePoint){
            return b.killPoint = a.killPoint;
        } else {
            return b.placePoint - a.placePoint;
        }
    } else {
        return b.matchPoint - a.matchPoint
    }
}
export const compareTotal = (a,b)=> {
    if(a.totalPoint===b.totalPoint){
        return b.killPoint - a.killPoint
    } else {
        return b.totalPoint - a.totalPoint
    }
}

export const compareKills = (a,b) => {
    if(a.killPoint === b.killPoint){
        return b.totalPoint - a.totalPoint;
    } else {
        return b.killPoint - a.killPoint;
    }
}

export const comparePlace = (a,b) => {
    if(a.placePoint === b.placePoint){
        return b.totalPoint - a.totalPoint;
    } else {
        return b.placePoint - a.placePoint;
    }
}
export const getMatchStanding = (match) =>{
    const PP = match.pointSystem.placePoint;
    const KP = match.pointSystem.killPoint;
    const teams = match.points;

    const standings = teams.map(team => {
        const placePoint = PP[team.place]? PP[team.place] : 0;
        const killPoint = KP * team.kills;
        const standing={
            placePoint,
            killPoint,
            kills: team.kills,
            place: team.place,
            name: team.name,
            matchPoint: placePoint+killPoint
        }
        return standing;
    })
    standings.sort(compareMatch);
    return standings;
}

export const getTournamentStanding = (tournament) => {
    const matchStandings = []; 
    const teams = new Map();
    const matches = tournament.matches;
    const PP = tournament.pointSystem.placePoint;
    const KP = tournament.pointSystem.killPoint;
    const wwcd = {
        cd: -1,
        team: null
    };
    tournament.teams.forEach(team =>{
        teams.set(team._id,{
            placePoint: 0,
            killPoint: 0,
            playerList: team.playerList,
            name: team.name,
            totalPoint: 0,
            matchesPlayed: 0,
            wwcd: 0,
            kills: 0,
            teamId: team._id,
            matchPoints: [],
        })
    })
    matches.forEach(match =>{
        const points = match.points;
        matchStandings.push(getMatchStanding({...match, pointSystem: tournament.pointSystem}));
        points.forEach(team => {
            const standing = teams.get(team.teamId);
            if(!standing)
                return;
            standing.placePoint += PP[team.place]? PP[team.place] : 0;
            standing.killPoint += KP * team.kills;
            standing.totalPoint = standing.placePoint + standing.killPoint;
            standing.kills += team.kills;
            standing.matchPoints.push({
                killPoint: KP * team.kills,
                placePoint: PP[team.place]? PP[team.place] : 0,
                matchPoint: (KP * team.kills) + (PP[team.place]? PP[team.place] : 0),
                kills: team.kills,
                place: team.place + 1,
                totalTillMatch: standing.totalPoint,
                placeTillMatch: standing.placePoint,
                killTillMatch: standing.kills
            })
            if(team.place === 0){
                standing.wwcd += 1;
                if(standing.wwcd > wwcd.cd){
                    wwcd.cd = standing.wwcd;
                    wwcd.team = standing;
                } else if( standing.wwcd === wwcd.cd){
                    if(standing.totalPoint > wwcd.team.totalPoint){
                        wwcd.cd = standing.wwcd;
                        wwcd.team = standing;
                    } else if( standing.totalPoint === wwcd.team.totalPoint ){
                        if(standing.killStandings > wwcd.team.killPoint){
                            wwcd.cd = standing.wwcd;
                            wwcd.team = standing;
                        }
                    }
                }
            }
            standing.matchesPlayed += 1;
            teams.set(team.teamId,standing);
        });
    });
    const it = teams.entries();
    const standings = [];
    let item;
    while((item = it.next().value)){
        standings.push(item[1]);
    }
    const stats = {};
    stats.totalStandings = Array.from(standings.sort(compareTotal));
    stats.killStandings = Array.from(standings.sort(compareKills));
    stats.placeStandings = Array.from(standings.sort(comparePlace));
    stats.wwcd = wwcd;
    stats.matchStandings = matchStandings;
    return stats;
}