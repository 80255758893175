import * as actionTypes from './actionTypes';

const initialState = {
    sendingFeedback: false,
    postFeedbackSend: null,
    showSignIn: false
}

export const Other = (state = initialState, action ) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case actionTypes.SENDING_FEEDBACK:
            newState.sendingFeedback = true;
            newState.postFeedbackSend = null;
            return newState;
        case actionTypes.SENT_FEEDBACK:
            newState.sendingFeedback = false;
            newState.postFeedbackSend = "success";
            return newState;
        case actionTypes.FAILED_FEEDBACK:
            newState.sendingFeedback = false;
            newState.postFeedbackSend = "failed";
            return newState;
        case actionTypes.FEEDBACK_POST_CONSUME:
            newState.postFeedbackSend = null;
            newState.sendingFeedback = false;
            return newState;
        case actionTypes.SHOW_SIGN_IN:
            newState.showSignIn=true;
            return newState;
        case actionTypes.HIDE_SIGN_IN:
            newState.showSignIn = false;
            return newState;
        default:
            return state;
    }
}