import React from 'react';
import {CustomStorage} from './../../functions/Storage'
import { Container, Row, Collapse } from 'reactstrap';
import { baseURL } from '../../config';
import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
class Notification extends React.PureComponent {
    constructor(props){
        super(props);
        this.store = new CustomStorage('notification');
        this.state = {
            show: false,
            open: false,
            hidden: false,
            notification: {text:'Loading'}
        }
        this.clickhandler = this.clickhandler.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    toggle(){
        this.store.addItem('count',this.state.notification.count);
        this.setState(prev=>({
            ...prev,
            show: false,
            open: !prev.open
        }))
    }
    getNotification(){
        fetch(baseURL+'/notification')
        .then(response=> {
            if(response.ok){
                return response.json();
            } else {
                throw new Error();
            }
        })
        .then(notif=>{
            if(notif.isOn){
                this.setState(prev=>({
                    ...prev,
                    notification: notif,
                    hidden: false,
                    show: this.store.getItem('count') !== notif.count
                }));
            } else {
                this.setState(prev=>({
                    ...prev,
                    notification: notif,
                    hidden: true
                }));
            }
        })
        .catch(()=>{
            this.setState(prev=>({
                ...prev,
                hidden: true
            }))
        })
    }
    clickhandler(e){
        if((!e.target.closest('.notification') && this.state.open) || e.target.closest('.notification-btn')){
            this.toggle();
        }
    }
    componentDidMount(){
        document.addEventListener('click',this.clickhandler);
        setTimeout(()=>{
            this.getNotification();
        },6000);
    }
    componentWillUnmount(){
        document.removeEventListener('click',this.clickhandler);
    }
    render(){
        if(this.state.hidden){
            return <div/>;
        } else {
            const n = this.state.notification;
            return (
                <div className="notification-container">
                    <Collapse isOpen={this.state.open}> 
                        <div className="notification">
                        <Container fluid>
                            <Row>
                                <h5>{n.title}</h5>
                            </Row>
                            {n.img && 
                                <Row>
                                    <img src={n.img} alt={n.title} className="img-fluid"/>
                                </Row>
                            }
                            <Row>
                                <p>
                                    <Typography use = 'body1'>{n.text}</Typography>
                                    <br/>
                                    <a href={n.link} 
                                        style={{textDecoration: "none"}}
                                        rel="noopener noreferrer" 
                                        target="_blank">
                                            <Button raised label={n.linkName}></Button>
                                    </a>
                                </p>
                            </Row>
                        </Container>
                    </div>
                    </Collapse>
                    <div className={"notification-btn"+(this.state.show?' has-notification':'')}>
                        <i className="fa fa-bell"></i>
                    </div>
                </div>
            )
        }
    }
}

export default Notification;