import * as actionTypes from './registrationActionTypes';
import {CustomStorage} from '../functions/Storage';
import analytics from '../components/firebase/analytics';
const initialState = {
    isLoading: true,
    formDataErr: null,
    form: null,
    isRegistering: false,
    registrationErr: null
}
function Registration( state = initialState ,  action){
    const newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case actionTypes.LOADING_FORM_PUBLIC_DATA:
            newState.isLoading = true;
            newState.formDataErr = null;
            return newState;
        case actionTypes.LOADING_FORM_PUBLIC_DATA_ERR:
            newState.isLoading = false;
            newState.formDataErr = action.payload;
            return newState;
        case actionTypes.LOADED_FORM_PUBLIC_DATA:
            newState.isLoading = false;
            newState.formDataErr = null;
            newState.form = action.payload;
            return newState;
        
        case actionTypes.ADDING_RESPONSE:
            newState.isRegistering = true;
            newState.registrationErr = null;
            return newState;
        
        case actionTypes.ADDING_RESPONSE_ERR:
            newState.isRegistering = false;
            newState.registrationErr = action.payload;
            return newState;
        
        case actionTypes.ADDED_RESPONSE:
            newState.isRegistering = false;
            newState.registrationErr = null;
            new CustomStorage('registration').addItem(action.payload.formId,action.payload);
            analytics.responseAdded();
            return newState;
        default:
            return state;
    }
}

export default Registration;