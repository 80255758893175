export const HOME='/';
export const DASHBOARD='/dashboard';
export const BLOG='/blog';
export const ENTER_MATCH =  "/enter-match-results";
export const SETTINGS = "/setting";
export const TEAMS = "/teams";
export const TEAM_IMPORT = "/import"
export const CONTACT = "/contact";
export const FAQ = "/faq";
export const PRIVACY = "/privacy-policy";
export const GUIDE = "/how-to-guide";
export const FORMS = '/form';
export const TOURNAMENT = '/tournament';
export const STATS = '/points';
export const DISCLAIMER = '/disclaimer';
export const TERMSOFUSE = '/termsofuse';
export const ABOUT = '/about';
export const tournamentViews =  {
    ENTER_MATCH: "enter-match-results",
    SETTINGS: "setting",
    TEAMS: "teams",
    FORMS: 'form',
    IMPORT: 'import'
}