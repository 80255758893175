//user related actions
export const USER_SIGNED_IN = "USER_SIGNED_IN";
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const SHOW_SIGN_IN = "SHOW_SIGN_IN";
export const HIDE_SIGN_IN = "HIDE_SIGN_IN"
//tournament related actions
export const FETCH_TOURNAMENTS = "FETCH_TOURNAMENTS";
export const TOURNAMENTS_LOADING = 'TOURNAMENTS_LOADING';
export const TOURNAMENTS_FETCHED = 'TOURNAMENTS_FETCHED';
export const TOURNAMENTS_FETCH_FAILED = 'TOURNAMENTS_FETCH_FAILED';

export const CREATE_TOURNAMENT = "CREATE_TOURNAMENTS";
export const TOURNAMENT_CREATION_IN_PROGRESS = 'TOURNAMENT_CREATION_IN_PROGRESS';
export const TOURNAMENT_CREATED = "TOURNAMENT_CREATED";
export const TOURNAMENT_CREATION_FAILED = "TOURNAMENT_CREATION_FAILED";
export const TOURNAMENT_CREATED_NOTIFY = "TOURNAMENT_CREATED_NOTIFY";
export const TOURNAMENT_CREATED_CONSUME_NOTIFY = "TOURNAMENT_CREATED_CONSUME_NOTIFY";

export const TOURNAMENT_DELETE = "TOURNAMENT_DELETE";
export const TOURNAMENT_DELETED = "TOURNAMENT_DELETED";

export const ACTIVE_TOURNAMENT_LOADING = "ACTIVE_TOURNAMENT_LOADING";
export const ACTIVE_TOURNAMENT_LOADING_FAILED = "ACTIVE_TOURNAMENT_LOADING_FAILED";
export const ACTIVE_TOURNAMENT_LOADED = "ACTIVE_TOURNAMENT_LOADED";
export const ACTIVE_TOURNAMENT_LOAD_FROM_CACHE = "ACTIVE_TOURNAMENT_LOAD_FROM_CACHE";

export const TEAM_CTREATING = "TEAM_CTREATING";
export const TEAM_CREATION_FAILED = "TEAM_CREATION_FAILED";
export const TEAM_CREATED = "TEAM_CREATED";
export const DELETED_TEAM = "DELETED_TEAM";
export const TEAM_SELECTED_FOR_MATCH = "TEAM_SELECTED_FOR_MATCH";
export const TEAM_DESELECTED_FOR_MATCH = "TEAM_DESELECTED_FOR_MATCH";
export const ADD_TEAM = "ADD_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM"
export const SET_POINT_SYSTEM = 'SET_POINT_SYSTEM';
export const CALC_STANDINGS_FOR_MATCH = "CALC_STANDINGS_FOR_MATCH";

export const SAVE_MATCH_ERR = "SAVE_MATCH_ERR";
export const SAVING_MATCH ="SAVING_MATCH";
export const MATCH_SAVED = "MATCH_SAVED";
export const SAVE_MATCH_NOTIFY =  "SAVE_MATCH_NOTIFY";
export const NEW_MATCH_RESET = "NEW_MATCH_RESET";
export const SAVE_MATCH_CONSUME_NOTIFY = "SAVE_MATCH_CONSUME_NOTIFY";

export const STATS_LOADING = "STATS_LOADING";
export const STATS_LOADED = "STATS_LOADED";
export const STATS_FAILED = "STATS_FAILED";

export const MATCH_DELETED = "MATCH_DELETED";

export const UPDATING_TOURNAMENT = "UPDATING_TOURNAMENT";
export const UPDATED_TOURNAMENT = "UPDATED_TOURNAMENT";
export const UPDATE_TOURNAMENT_FAILED = "UPDATE_TOURNAMENT_FAILED";
export const UPDATED_TOURNAMENT_NOTIFICATION_CONSUME = "UPDATED_TOURNAMENT_NOTIFICATION_CONSUME";

export const SENDING_FEEDBACK = "SENDING_FEEDBACK";
export const SENT_FEEDBACK = "SENT_FEEDBACK";
export const FAILED_FEEDBACK = "FAILED_FEEDBACK";
export const FEEDBACK_POST_CONSUME = "FEEDBACK_POST_CONSUME";

export const CREATING_FORM = "CREATING FORM";
export const CREATE_FORM_FAILED =  "CREATE_FORM_FAILED";
export const FORM_CREATED = "FORM_CREATED";
export const LOADING_FORM_DATA = "LOAD_FORM_DATA";
export const LOAD_FORM_DATA_ERR = "LOAD_FORM_DATA_ERR";
export const LOADED_FORM_DATA = "LOADED_FORM_DATA";

export const UPDATING_FORM_SETTINGS = "UPDATING_FORM_SETTINGS";
export const UPDATED_FORM_SETTINGS = "UPDATED_FORM_SETTINGS";
export const UPDATE_FORM_SETTINGS_FAILED = "UPDATE_FORM_SETTINGS_FAILED";

export const APPROVING_RESPONSE = "APPROVING_RESPONSE";
export const APPROVING_RESPONSE_ERR = "APPROVING_RESPONSE_ERR";
export const APPROVED_RESPONSE = "APPROVED_RESPONSE";

export const TOGGLING_TOURNEY_STATUS = "TOGGLING_TOURNEY_STATUS";
export const TOGGLING_TOURNEY_STATUS_ERR = "TOGGLING_TOURNEY_STATUS_ERR";
export const TOGGLED_TOURNEY_STATUS = "TOGGLED_TOURNEY_STATUS";