import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import JumbotronButton from './microComponents/JumbotronButton';
import { Link } from 'react-router-dom';
import * as routes from '../routes';
import { connect } from 'react-redux';
import { strings } from '../strings';
import DevInfo from './DevInfo';
import { appUrl } from '../config';
import { Button } from '@rmwc/button';

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

class Lander extends React.Component {

    render(){
        return (     
            <div className="lander"> 
                <Container fluid="true" style={{overflow: "hidden"}} className="jumbotron d-flex align-items-center">
                    <Row className="text-center">
                        <Col xs={12}>
                            <img src={appUrl+'/logo512.png'} alt={strings.appName} className="img-fluid jumbotron-img"/>
                        </Col>
                        <Col xs={12} className="jumbotron-heading lead ">
                            <p>Managing eSports tournaments made easy with {strings.appName}</p>
                        </Col>
                        <Col xs={12} className="jumbotron-text pl-3 pr-3">
                            <p>{strings.appName} is a tournament management app made for eSports lover. Manage multiple teams and matches. Points table is generated automatically. Tournify Comes with inbuilt Points table maker and registration forms.</p>
                        </Col>
                        <Col xs={12}>
                            <Link to={routes.DASHBOARD}><JumbotronButton/></Link>
                            <a href='https://play.google.com/store/apps/details?id=in.tournify.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' style={{width: "220px", height: "auto"}} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        </Col>
                        <Col xs={12} className="mt-1">
                            <p style={{fontSize: "1.2rem"}}>
                               Try our premium website  
                            </p>
                        </Col>
                        <Col xs={12}>
                            <a href="https://esportsweb.in" style={{textDecoration: "none"}}>
                                <Button raised
                                    icon={
                                        <div style={{width: "70%", display: "flex", alignItems: "center", height: "70%"}}>
                                            <img src="https://esportsweb.in/favicon.ico" alt="esportsweb.in"/>
                                        </div>
                                    } 
                                    style={{background: "#fff", color: "var(--mdc-theme-primary)"}}>
                                    Get eSportsWeb.in
                                </Button>
                            </a>
                        </Col>
                        <Col xs={12} className="mt-1">
                            <a href="https://www.youtube.com/watch?v=UFzYS6RxEg8" style={{textDecoration: "none"}}>
                                <Button raised
                                    icon={<i className="fa fa-youtube"/>}
                                    style={{background: "#fff", color: "var(--mdc-theme-primary)"}}>
                                    Watch Tutorial
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
                <Container className="mb-5">
                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center">
                            <img className="img-fluid rounded border" width="300px" src={`${appUrl}/images/create_tournament.jpg`} alt="Create Tournament Form"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                            <div>
                                <h3 className="lead jumbotron-heading">
                              Create and manage tournaments in a go.  
                            </h3>
                            <ul className="list-unstyled p-2">
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Easy creation using a single form.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Manage multiple matches and teams.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Add your own point system.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Add or disqualify team with a single click.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Manage reports for each and every match. 
                                </li>
                            </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center order-sm-last">
                            <img className="img-fluid rounded border" width="300px" src={`${appUrl}/images/entry_data_ss.jpg`} alt="Enter Match results"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                            <div>
                                <h3 className="lead jumbotron-heading order-sm-first">
                                    Adding results of match made easy
                                </h3>
                                <p>
                                    The process of entering results data of a match has been been super user friendly. User experience has been the top most priority. 
                                </p>
                                <ul className="list-unstyled p-2">
                                    <li>
                                        <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Add the participating teams once.
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}While entering results data, just select the team and no. of kills. We take care of the points calculation.
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Select a team by just typing its player's name or the team name itself.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center">
                            <img className="img-fluid rounded" width="300px" src={`${appUrl}/images/stats1.jpg`} alt="Demo Points Table"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                           <div>
                           <h3 className="lead jumbotron-heading">
                                Auto-generated beautiful points table
                            </h3>
                            <p>
                                A beautiful points table is generated for your tournament. The standings are calculated using the points system provided during the creation of points table.
                            </p>
                            <ul className="list-unstyled p-2">
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}A dedicated points table web page is created solely for your tournament.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Points table is updated automatically each time you enter results of a new match.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Option to export the points table to an image file.
                                </li>
                                <li>
                                    <i className="fa fa-check-circle --mdc-theme-primary"></i>{' '}Detailed analytics.
                                </li>
                                <li>
                                    <Link to={routes.STATS+'/5efcbed2ae3c3d3a04c0332a'} style={{textDecoration: "none"}}>
                                        <Button raised label="SEE DEMO"/>
                                    </Link>
                                </li>
                            </ul>
                           </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={5} className="p-4 d-flex align-self-center jusitify-content-center order-sm-last">
                            <img className="img-fluid rounded" width="300px" src={`${appUrl}/images/registration-form1.jpg`} alt="Demo Points Table"></img>
                        </Col>
                        <Col xs={12} sm={7} className="d-flex align-self-center">
                           <div>
                           <h3 className="lead jumbotron-heading">
                                Comes with pre-built regsitration form
                            </h3>
                            <p>
                                With each eSports tournament you will get a dedicated registration form. It can aslo be used as a web page for your eSports tournamnt.
                            </p>
                           </div>
                        </Col>
                    </Row>
                </Container>
                <DevInfo/>
                </div>
            );
    }
}

export default connect(mapStateToProps)(Lander);

