/* eslint-disable */
export const validators = {
    required : (val)=> val&&(Array.isArray(val)?val.length:true),

    maxLength : len => val => !val || val.length<=len,

    minLength : len => val => val && val.length >= len,

    isNumber : val => /^[0-9]+$/.test(val),

    isRange : val =>  /^([0-9]+|([0-9]+\s*-\s*[0-9]+))$/.test(val),

    isValidPointSystem: val => isValidPointSystem(val),
    isAlphaNumericWithSpace : val => {
        return /^[a-zA-Z0-9 ]*$/.test(val);
    },
    isAlphaWithSpace : val => {
        return /^[a-zA-Z ]*$/.test(val);
    },
    isAlphaNumericWithoutSpace : val => {
        return /^[a-zA-Z0-9]*$/.test(val);
    },
    isWholeNumber : val => {
        return /^\d+$/.test(val);
    },
    isNumeric : val => {
        return /^\d+$/.test(val);
    },
    isEmail : val => {
        return /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/.test(val);
    },
    isUnCommonEmail : val => {
        return /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(val);
    },

    //Should have 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be at least 8 characters long
    isComplexPassword : val => {
        return /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/ .test(val);
    },
    //Should have 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long
    isModeratePassword : val => {
        return /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/.test(val);
    },
    //Alphanumeric string that may include _ and – having a length of 3 to 16 characters
    isUsername : val => {
        return /^[a-z0-9_-]{3,16}$/.test(val);
    },
    isURLWithHttp : val => {
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/ .test(val);
    },
    isURLWithHttpOrNull : val => {
        if(val)
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/ .test(val);
        else
        return true;
    },
    isURLWithHttpOptional : val => {
        return /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ .test(val);
    },
    //international number with optional country code
    isPhoneNumber : val => {
        return /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/
        .test(val);
    },
    isBase64: val => {
        return /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(val);
    }

}

const isValidPointSystem= val => {
    let pointSystem = val.replace(/\s/g,'').split(',');
    let  i = 0, dp = {},a,b,max=-1,flag= [];

    try {
        pointSystem.forEach( (el) => {
              if(!el)
              return;
            let p = el.split('=');
            if( ! (validators.isNumber(p[1]) && validators.isRange(p[0]) ) ){
                throw new Error();
            }
            if(p[0].indexOf('-') !== -1){
                [a,b] = p[0].split('-');
                a = parseInt(a)-1;
                b = parseInt(b)-1;
            } else {
                a = b = parseInt(p[0]) - 1;
            }
            if(a < 0 || b < 0 || a > 100 || b > 100 || a > b){
                throw new Error(); 
            }
            for(;a<=b;a++){
               if(dp[a]){
                throw new Error();
               } else {
                   dp[a] = true;
               }
            }
              max = max < b ? b : max;
        });
          for( i = 0 ; i <= max ; i++ ){
          if(!dp[i]){
            flag.push(i+1);
          }
        }
          if(flag.length){
          throw new Error();
        }
    }catch(err) {
        return false;
    }
    return true;
}