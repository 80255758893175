import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {User} from './User';
import {Tournaments} from './Tournaments';
import { createForms } from 'react-redux-form';
import { createTournamentForm, newTeam, newMatch, response } from './formInitState';
import NewMatchData from './NewMatchData';
import StatsReducer from './StatsReducer';
import { Other } from './Other';
import Registration from './Registration';

export const configureStore = () => {
    const store = createStore(
        combineReducers({
            user: User,
            tournaments: Tournaments,
            ...createForms({
                newTournament: createTournamentForm,
                newTeam: newTeam,
                newMatch: newMatch,
                updateTournament: createTournamentForm,
                formSettings: {},
                response: response
            }),
            newMatchData: NewMatchData,
            stats: StatsReducer,
            other: Other,
            registration: Registration
        }),
        applyMiddleware(thunk)
    );
    return store;
}